import { Anchor, Flex, Text, UnstyledButton } from "@mantine/core";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { IconX } from "@tabler/icons-react";

export function CookieBanner() {
  const [visible, setVisible] = useLocalStorage("visible", true);

  return (
    <>
      {visible && (
        <Flex className="cookie-banner">
          <Text size="xs" mr="lg">
            This site uses cookies for analytics & personalization. For more
            info see our{" "}
            <Anchor underline target="_blank" href="/privacy-policy">
              Privacy Policy & Cookie Notice
            </Anchor>
            .
          </Text>
          <UnstyledButton onClick={() => setVisible(false)}>
            <IconX />
          </UnstyledButton>
        </Flex>
      )}
    </>
  );
}
